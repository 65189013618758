<template>

    <!-- <div class="page-container"> -->
        
        <div class="iframe-wrapper">
            <iframe 
                id="iframe"
                :src="iframeSrc"
                ref="iframe" 
                class="iframe"
            ></iframe>
            <!-- <div class="mask" v-show="showMask"></div> -->
        </div>
        
    <!-- </div> -->

</template>

<script>
export default {
    name: 'iframe-exam-scratch',
    data: function() {
        return {
            iframeSrc: '',
            iframeWin: {},
            showMask: true
        }
    },
    activated() {
        let that = this

        // 解决iframe加载刷新页面时会闪一下的问题
        document.getElementById('iframe').onload = function() {
            setTimeout(() => { that.showMask = false }, 300);
        }

        // 接收页面参数
        let examId = this.$route.query.examId
        let examNumber = this.$route.query.examNumber
        let qid = this.$route.query.qid
        let path = this.$route.path

        // 更新iframe页面
        this.iframeSrc = '/iframe' + path + '?examId=' + examId + '&examNumber=' + examNumber + '&qid=' + qid + '&r=' + Math.random()
        this.iframeWin = this.$refs.iframe.contentWindow
    },
    deactivated() {
        this.showMask = true
    },
    methods: {
    },
    components: {
    }
}
</script>

<style lang="scss" scoped>
// .page-container {
//     height: 100%;
//     width: 100%;
//     overflow: hidden;
//     display: flex;
//     flex-direction: column;
//     background-color: #222;
// }
.iframe-wrapper {
    height: 100%;
    width: 100%;
    overflow: hidden;
    background-color: #222;
    position: relative;
    .iframe {
        height: 100%;
        width: 100%;
        overflow: hidden;
    }
    .mask {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #222;
    }
}
</style>
